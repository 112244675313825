import React from "react";
import styles from "./CandidateTable.module.css";
import FilterButton from "./FilterButton";
import Pagination from "../Global/RecruiterSection/Pagination";
import CandidateRow from "./CandidateRow";
import { paginate } from "project-name/util/paginationUtils";
import { usePagination } from "project-name/util/tableUtils";

interface SavedProfile {
  full_name: string;
  tower: string;
  function: string;
  employee_band: string;
  office_location: string;
  bgv_status: string;
  d_o_j: string;
  is_documents_moved: string;
  status_name: string;
  user_id: number;
}

interface CandidateTableProps {
  profiles: SavedProfile[]; // Explicitly declare profiles as a prop
}

const CandidateTable: React.FC<CandidateTableProps> = ({ profiles }) => {
  const itemsPerPage: number = 5; // Number of items to display per page

  // Use custom hook for pagination
  const { currentPage, totalPages, handlePageChange } = usePagination(
    profiles.length,
    itemsPerPage
  );

  return (
    <section>
      <div className={styles.savedProfilesContainer}>
        <div className={styles.savedProfilesHead}>
          <h4>Candidate profiles</h4>
          <div>
            <FilterButton />
          </div>
        </div>
        <div className={styles.savedProfilesListContainer}>
          <div className={styles.savedProfilesListTitle}>
            <p>Name</p>
            <p>Tower</p>
            <p>Function</p>
            <p>Band</p>
            {/* <p>Location</p> */}
            <p>BGV Status</p>
            <p>Joining Date</p>
            <p>Status</p>
          </div>
          {paginate(profiles, currentPage, itemsPerPage).map((profile, index) => (
            <CandidateRow key={index} profile={profile} />
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </section>
  );
};

export default CandidateTable;
