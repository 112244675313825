import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Typography, InputAdornment, IconButton } from '@mui/material';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import styles from "../CandidatePages/chatbot.module.css"
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,

  Avatar,
} from "@chatscope/chat-ui-kit-react";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'project-name/Redux/Store';
import { AppDispatch } from 'project-name/Redux/Store';
import { fetchChatResponse } from 'project-name/Redux/features/ChatbotSlice';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import users from "project-name/assets/users.png";
import avatar from "../../assets/img/Avatar.png";

interface ChatMessage {
  message: string;
  direction: "incoming" | "outgoing";
  sender: string;
}

const Chatbot: React.FC = () => {
  const { response, loading, error } = useSelector((state: RootState) => state.chat);
  const [message, setMessage] = useState("");
  const [isMessageSent, setIsMessageSent] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const dispatch = useDispatch<AppDispatch>();;

  const handleSend = (message: string) => {
    setMessages(prevMessages => [
      ...prevMessages,
      { message, direction: "outgoing", sender: "You" },
    ]);
    dispatch(fetchChatResponse(message));
  };

  useEffect(() => {
    if (response) {
      const responseMessage = response.response
      setMessages(prevMessages => [
        ...prevMessages,
        { message: responseMessage, direction: "incoming", sender: "Bot" },
      ]);
    }
  }, [response]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleSendClick = () => {
    if (message.trim()) {
      setIsMessageSent(true);
      handleSend(message);
      setMessage("");
    }
  };

  const handleAttachmentClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      console.log('Selected file:', files[0]);
      // Handle the selected file here
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      {isMessageSent ? (
        <Box

          display="flex"
          flexDirection="column"
          height="100vh"
          bgcolor={"#f9f9f9"}
          width={"85%"}
          margin={"auto"}

        >
          <MainContainer >
            <ChatContainer>
              <MessageList className={styles.chatContainer}>
                {messages.map((msg, index) => (
                  <Box key={index} marginBottom={1} display="flex" flexDirection="column"   >
                    {/* Avatar and Sender Info */}
                    <Box display="flex" alignItems="center" marginBottom={1}>
                      {msg.sender === "Bot" ? (
                        <>
                          <Avatar src={avatar} />
                          <Typography sx={{ marginLeft: 1, fontWeight: 600 }}>
                            Bot
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Avatar src={users} style={{ width: "50px" }} />
                          <Typography sx={{ marginLeft: 1, fontWeight: 600 }}>
                            You
                          </Typography>
                        </>
                      )}
                    </Box>

                    {/* Message Content */}
                    <Message
                      className={styles.myMessage}
                      model={{
                        message: msg.message,
                        sentTime: getCurrentTime(),
                        sender: msg.sender,
                        direction: msg.sender === "Bot" ? "incoming" : "incoming",
                        position: "single",

                      }}
                      style={{

                        borderRadius: "10px",
                        padding: "10px",
                      }}
                    />

                  </Box>

                ))}
              </MessageList>

              <MessageInput
                placeholder="Type a message here..."
                onSend={handleSend}
                style={{
                  color: '#0C3276',
                  backgroundColor: 'white',
                  width: '100%',
                  height: '45px'

                }}
              />
            </ChatContainer>
          </MainContainer>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          textAlign="center"
          bgcolor={"#f9f9f9"}
          width={"85%"}
          margin={"auto"}
        >

          <Typography variant="h6" gutterBottom sx={{ fontSize: "18px", fontWeight: 700, mt: 5 }}>
            Welcome to <span style={{ color: "gold", fontWeight: 700, backgroundColor: "black", padding: 5, fontSize: "18px", borderRadius: 3 }}>Chatbot</span>
          </Typography>
          {/* <Typography marginBottom={4} sx={{ fontSize: "14px", fontWeight: 600 }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </Typography> */}
          {/* <Box> */}
            {/* <Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
              <Box sx={{ backgroundColor: "#f1f2f6", padding: 3, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: 600, fontSize: "16px" }} gutterBottom>
                    Feature 1 here
                  </Typography>
                  <img src='' alt='img' />
                </Box>
                <Box><Typography sx={{ fontSize: "12px", width: '285px' }}>Lorem ipsum dolor sit amet consectetur. Faucibus in und esbar nisi arcu morbi erat quam rhoncus enima.  </Typography></Box>
              </Box>
              <Box sx={{ backgroundColor: "#f1f2f6", padding: 3, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: 600, fontSize: "16px" }} gutterBottom>
                    Feature 2 here
                  </Typography>
                  <img src='' alt='img' />
                </Box>
                <Box><Typography sx={{ fontSize: "12px", width: '285px' }}>Lorem ipsum dolor sit amet consectetur. Faucibus in und esbar nisi arcu morbi erat quam rhoncus enima.  </Typography></Box>
              </Box>
            </Box>
            <Box></Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
              <Box sx={{ backgroundColor: "#f1f2f6", padding: 3, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: 600, fontSize: "16px" }} gutterBottom>
                    Feature 3 here
                  </Typography>
                  <img src='' alt='img' />
                </Box>
                <Box><Typography sx={{ fontSize: "12px", width: '285px' }}>Lorem ipsum dolor sit amet consectetur. Faucibus in und esbar nisi arcu morbi erat quam rhoncus enima.  </Typography></Box>
              </Box>
              <Box sx={{ backgroundColor: "#f1f2f6", padding: 3, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: 600, fontSize: "16px" }} gutterBottom>
                    Feature 4 here
                  </Typography>
                  <img src='' alt='img' />
                </Box>
                <Box><Typography sx={{ fontSize: "12px", width: '285px' }}>Lorem ipsum dolor sit amet consectetur. Faucibus in und esbar nisi arcu morbi erat quam rhoncus enima.  </Typography></Box>
              </Box>
            </Box>
          </Box> */}
          <Box display="flex" flexDirection="column" alignItems="center" sx={{ width: "94%", margin: "auto", mt: 8 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a new message here"
              value={message}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleAttachmentClick}>
                      <AttachFileIcon />
                    </IconButton>
                    <IconButton onClick={handleSendClick} disabled={!message.trim()}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                borderRadius: 2,
                border: "none",
                backgroundColor: "#f1f2f6",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none', // Remove the border from the fieldset
                  },
                },
              }}
            />
          </Box>
        </Box>
      )}
    </div>
  );
}

export default Chatbot;