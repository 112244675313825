import React, { useEffect, useState } from 'react';
import styles from './doctable4.module.css'; // Import CSS module
import { Box, Button, TableCell, styled } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FaRegFilePdf } from "react-icons/fa";
import { BsFiletypePng } from "react-icons/bs";
import { BsFiletypeJpg } from "react-icons/bs";
import DocTablePhoneScreen from '../docTablePhoneScreen';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoIosHelpCircleOutline } from "react-icons/io";
import AccessTimeIcon from '@mui/icons-material/AccessTime';



import { Link } from 'react-router-dom';
import { AckIdsToCheck, addIdsToCheck, bankIdsToCheck, docTypeIdsToCheck, getSubmitPath, optionalEducationTypes, otherIdsToCheck, perIdsToCheck, requiredDocTypes, RequiredEducationTypes } from 'project-name/util/util';

import apiClient from 'project-name/api/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'project-name/Redux/Store';
import { Tab, Tabs } from '../phoneScreenTabs';
import Layer2 from '../../Documents/Layer2';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { setStatusList } from 'project-name/Redux/features/DocumentStatus';
import { setCandidate, setPersonalDetails } from 'project-name/Redux/features/Candidate';
import { setOtherCompletedDocuments } from 'project-name/Redux/features/OtherCompletedDocuments';
import { setEducationDetails } from 'project-name/Redux/features/Education';
import PhoneMenu from '../../Global/Navbar/phoneMenu';

interface Document {
  id: number;
  user_id: number;
  status_id: number;
  doc_type_id: number;
  doc_path: string;
  created_at: string;
  updated_at: string | null;
  doc_type_name: string;
}

interface DocumentCategory {
  id: number;
  name: string;
  status: string
  type: string | null;
  deadline?: string | null;
}

interface Doctable4Props {
  thead1: string;
  thead2: string;
  thead3: string;
  thead4: string;
  thead5: string;
  variants?: string[];
}

const Doctable4: React.FC<Doctable4Props> = ({ thead1, thead2, thead3, thead4, thead5, variants }) => {
  let statusList = useSelector((state: RootState) => state.status.statusList);
  let deadline = useSelector((state: RootState) => state.status.deadline);
  const [candidateType, setCandidateType] = useState("");

  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  let docTypeIdsWithStatus105: number[] = [];

  const filterDocsByStatus = (
    docs: { doc_type_id: number; status_id: number }[],
    docTypeIdsToCheck: number[]
  ) => {
    return docs
      .filter((doc: { doc_type_id: number; }) => docTypeIdsToCheck.includes(doc.doc_type_id))
      .map((doc: { status_id: number; doc_type_id: number }) => {
        if (doc.status_id === 105) {
          docTypeIdsWithStatus105.push(doc.doc_type_id);
        }
        return doc.status_id !== 105;
      });

  };

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`/api/candidate/candidate-details`, {
        withCredentials: true,
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch candidate details: ${response.status}`);
      }
      const status = [];
      const data = response.data;

      if (data) {
        if (data.candidate) {
        
          sessionStorage.setItem("candidateName",data?.candidate?.full_name ?? "")
          const candidate_type = data.candidate.candidate_type;
          let acknowledgement = data.candidate && data.candidate.is_acknowledged == "Yes";
          let personal_details_status = data.personal_details && data.personal_details.length !== 0;
          let address_status = data.address && data.address.length !== 0;
          const bank_status = data.bank_details && data.bank_details.length !== 0;
          const hasAllEducationTypes = RequiredEducationTypes.some(educationType =>
            data.education_details.some((doc: { education_type: string; }) => doc.education_type === educationType)
          );

          const canDocs = data.candidate_documents;

          const educationDocStatus = filterDocsByStatus(canDocs, docTypeIdsToCheck);
          const ackDocsStatus = filterDocsByStatus(canDocs, AckIdsToCheck)?.every(status => status === true);
          const perDocsStatus = filterDocsByStatus(canDocs, perIdsToCheck)?.every(status => status === true);

          const addStatus = filterDocsByStatus(canDocs, addIdsToCheck)?.every(status => status === true);
          const bankStatus = filterDocsByStatus(canDocs, bankIdsToCheck)?.every(status => status === true);

          const allDocsValid = educationDocStatus.every(status => status === true);

          let education_status;
          if (hasAllEducationTypes) {
            education_status = "Completed";
          } else if (!allDocsValid) {
            education_status = "Rejected";
          } else if (!hasAllEducationTypes) {
            education_status = "Pending";
          } else {
            education_status = 0;
          }

          // const education_status = hasAllEducationTypes ? "Completed" : 0;
          const matchingEducationTypes = data.education_details.filter((doc: { education_type: string; }) =>
            RequiredEducationTypes.includes(doc.education_type) || optionalEducationTypes.includes(doc.education_type)
          );


          const work_status = data.work_experience && data.work_experience.length !== 0;
          const hasAllRequiredDocs = requiredDocTypes.some(docType =>
            data.candidate_documents.some((doc: { doc_type_name: string; }) => doc.doc_type_name === docType)
          );

          const other_status = hasAllRequiredDocs ? "Completed" : data.other_documents && data.other_documents.length !== 0;
          const matchingDocuments = data.candidate_documents.filter((doc: { doc_type_name: string; }) =>
            requiredDocTypes.includes(doc.doc_type_name)
          );
          const otherStatus = filterDocsByStatus(canDocs, otherIdsToCheck)?.every(status => status === true);

          localStorage.setItem("matchingEducationTypes", JSON.stringify(matchingEducationTypes));
          localStorage.setItem("docTypeIdsWithStatus105", JSON.stringify(docTypeIdsWithStatus105));

          localStorage.setItem("matchingDocuments", JSON.stringify(matchingDocuments));
          const totalKeys = 7; // Total number of keys to check
          let completedKeys = 0;
          if (acknowledgement) completedKeys++;
          if (personal_details_status) completedKeys++;
          if (address_status) completedKeys++;
          if (bank_status) completedKeys++;
          if (education_status) completedKeys++;
          if (work_status) completedKeys++;
          if (other_status) completedKeys++;
          const percentage = (completedKeys / totalKeys) * 100;
          const createdDate = dayjs(data.candidate.created_at);
          const deadline = createdDate.add(3, 'day').format('YYYY-MM-DD');
          status.push(
            {
              name: 'Acknowledgement form',
              status: !ackDocsStatus ? 'Rejected' : acknowledgement ? 'Completed' : 'Pending',
              type: '.pdf'
            },
            {
              name: 'Education documents',
              status: education_status,
              type: '.pdf'
            },
            {
              name: 'Personal details',
              status: !perDocsStatus ? 'Rejected' : personal_details_status ? 'Completed' : 'Pending',
              type: '.pdf'
            },
            {
              name: 'Address details',
              status: !addStatus ? 'Rejected' : address_status ? 'Completed' : 'Pending',
              type: '.pdf'
            },
            {
              name: 'Bank account details',
              status: !bankStatus ? 'Rejected' : bank_status ? 'Completed' : 'Pending',
              type: '.pdf'
            }
          );

          if (candidate_type !== "Intern") {
            status.push(
              {
                name: 'Statutory documents',
                status: !otherStatus ? 'Rejected' : other_status ? 'Completed' : 'Pending',
                type: '.pdf'
              },
              {
                name: 'Work experience',
                status: work_status ? 'Completed' : 'Pending',
                type: '.pdf'
              }
            );
          }
          dispatch(setStatusList({ statusList: status, percentage: percentage, deadline: deadline }));
          dispatch(setOtherCompletedDocuments(matchingDocuments))
          dispatch(setEducationDetails(matchingEducationTypes))
          setCandidateType(candidate_type);

        }
        dispatch(setCandidate(data.candidate));
        dispatch(setPersonalDetails(data.personal_details));
        localStorage.setItem("candidateData", JSON.stringify(data.candidate));
        localStorage.setItem("personalDetails", JSON.stringify(data.personal_details));
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
      const FailedStatus = [];
      setIsError(true);
      FailedStatus.push(
        {
          name: 'Acknowledgement form',
          status: 'Pending',
          type: '.pdf',
          error: 'Failed to fetch data',
        },
        {
          name: 'Education documents',
          status: 'Pending',
          type: '.pdf',
          error: 'Failed to fetch data',
        },
        {
          name: 'Personal details',
          status: 'Pending',
          type: '.pdf',
          error: 'Failed to fetch data',
        },
        {
          name: 'Address details',
          status: 'Pending',
          type: '.pdf',
          error: 'Failed to fetch data',
        },
        {
          name: 'Bank account details',
          status: 'Pending',
          type: '.pdf',
          error: 'Failed to fetch data',
        }
      );
      
      if (candidateType !== "Intern") {
        FailedStatus.push(
          {
            name: 'Statutory documents',
            status: 'Pending',
            type: '.pdf',
            error: 'Failed to fetch data',
          },
          {
            name: 'Work experience',
            status: 'Pending',
            type: '.pdf',
            error: 'Failed to fetch data',
          }
        );
      }
      disp
      dispatch(setStatusList({ statusList: FailedStatus, percentage: 0, deadline: "" }));
    }

  }

  // if (!isError && (statusList.length === 0 || !deadline)) {
  //   fetchData();
  // }
  useEffect(() => {
    fetchData();
  },[]);

  const currentDate = dayjs();
  const deadlineDate = dayjs(deadline);
  const differenceInDays = deadlineDate.diff(currentDate, 'day');

  const deadlineMessage = differenceInDays < 0
    ? `Past ${Math.abs(differenceInDays)} day${Math.abs(differenceInDays) === 1 ? '' : 's'}`
    : `Due in ${differenceInDays} day${differenceInDays === 1 ? '' : 's'}`;

  const getStatusIcon = (status: string) => {
    if (status === 'Completed') {
      return <CheckCircleIcon style={{ color: '#026547' }} />;
    } else if (status === 'Rejected') {
      return <CancelIcon style={{ color: '#C01212' }} />;
    } else if (status === 'Pending') {
      return <AccessTimeIcon style={{ color: '#745500' }} />;
    }
    return null;
  };

  const StatusCell = styled(TableCell) <{ status: string }>`
    display: flex;
    align-items: center;
    border: none;
    color: ${({ status }) =>
      status === 'Uploaded' ? 'green' : status === 'Rejected' ? 'red' : status === 'Pending' ? '#745500' : 'inherit'};
  `;

  const getTypeIcon = (docPath: string) => {
    const fileType = docPath.split('.').pop();
    if (fileType === 'pdf') {
      return <FaRegFilePdf style={{ fontSize: '22px' }} />;
    } else if (fileType === 'png') {
      return <BsFiletypePng style={{ fontSize: '22px' }} />;
    } else if (fileType === 'jpeg') {
      return <BsFiletypeJpg style={{ fontSize: '22px' }} />;
    }
    return null;
  };


  const renderActionCell = (status: string, name: string) => {
    const submitPath = getSubmitPath(name);
    if (status === 'Uploaded') {
      return (
        <div className={styles['actionDiv']}>
          <Link to="/#">
            <CiEdit className={styles['iconDiv']} />
          </Link>
          <Link to="/#">
            <RiDeleteBinLine className={styles['iconDiv']} />
          </Link>
          <Link to={`/document-help?param=${name}`} title='help'>
            <IoIosHelpCircleOutline className={styles['iconDiv']} />
          </Link>
        </div>
      );
    } else if (status === 'Rejected') {
      return (
        <div className={styles['actionDiv2']}>
          <Link to={submitPath}>
            <Button color="primary" variant="contained" sx={{ width: "120px" }}>
              Resubmit
            </Button>
          </Link>
          <Link to={`/document-help?param=${name}`}>
            <IoIosHelpCircleOutline className={styles['iconDiv']} />
          </Link>
        </div>
      );
    } else if (status === 'Pending') {
      return (
        <div className={styles['actionDiv2']}>
          <Link to={submitPath}>
            <Button color="primary" variant="contained" sx={{
              width: "120px", textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "black", // Hover background color
              },
            }}>
              Submit
            </Button>
          </Link>
          <Link to={`/document-help?param=${name}`}>
            <IoIosHelpCircleOutline className={styles['iconDiv']} />
          </Link>
        </div>
      );
    }
    else if (status === 'Completed') {
      return (
        <div className={styles['actionDiv2']} style={{ pointerEvents: "none" }}>
          <Link to={submitPath} >
            <Button color="primary" variant="contained" sx={{ backgroundColor: "grey", width: "120px", textTransform: "capitalize" }} disabled>
              Submitted
            </Button>
          </Link>
          <Link to={`/document-help?param=${name}`}>
            <IoIosHelpCircleOutline className={styles['iconDiv']} />
          </Link>
        </div>
      )
    }
    return null;
  };
  const hasError = statusList.some(item => item.error);
  statusList = (variants ?? []).includes("All documents")
    ? statusList
    : (variants ?? []).length > 0
      ? statusList.filter((doc) => (variants ?? []).includes(doc.status))
      : statusList;

  return (
    <>

      <Tabs>
        <Tab label="All">
          <div>This is the content of Tab 1</div>
        </Tab>
        <Tab label="Pending">
          <div>This is the content of Tab 2</div>
        </Tab>
        <Tab label="Submitted">
          <div>This is the content of Tab 3</div>
        </Tab>
        <Tab label="Approved">
          <div>This is the content of Tab 3</div>
        </Tab>
        <Tab label="Rejected">
          <div>This is the content of Tab 3</div>
        </Tab>
      </Tabs>
      <div className={styles['documents-list-container']}>
        <TableContainer component={Paper} className={styles.tableDiv} sx={{ boxShadow: "none", border: "1px solid transparent", backgroundColor: "transparent", padding: "0px 8px 0px 8px" }}>
          <Table className={styles.candidateDetailsTAb}>
            {/* Table Header */}
            <TableHead>
              <TableRow className={styles['documents-list-title']} style={{ backgroundColor: "#000" }}>
                <TableCell sx={{ color: "white", fontWeight: "bold", padding: "12px", cursor: "pointer" }}>{thead1}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold", padding: "12px", cursor: "pointer" }}>{thead2}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold", padding: "12px", cursor: "pointer" }}>{thead3}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold", padding: "12px", cursor: "pointer" }}>{thead4}</TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold", cursor: "pointer" }}>{thead5}</TableCell>
              </TableRow>
            </TableHead>

            {/* Error Handling */}
            {isError ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5}>
                    <div className={styles.overlay}>
                      <div className={styles.overlayContent}>
                        <p>Server is currently unavailable</p>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className={styles['documents-list-rows-container']} >
                {statusList.map((doc, index) => (
                  <TableRow key={index} className={styles['documents-list-rows']} sx={{ borderBottom: "1px solid #ddd" }}>
                    {/* Document Name */}
                    <TableCell sx={{ fontWeight: "600", fontSize: "13px", cursor: "pointer" }}>{doc.name}</TableCell>

                    {/* Document Type */}
                    <TableCell sx={{}}>
                      <div className={styles.docType} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                        {getTypeIcon(doc.type)}

                      </div>
                    </TableCell>

                    {/* Deadline Message */}
                    <TableCell sx={{ fontWeight: "600", fontSize: "13px", cursor: "pointer" }}>
                      {deadlineMessage}
                    </TableCell>

                    {/* Document Status */}
                    <TableCell sx={{ p: 0, cursor: "pointer" }}  >
                      <StatusCell status={doc.status}>
                        <p style={{ display: "flex", alignItems: "center", gap: "5px", fontWeight: "600", fontSize: "13px" }}>
                          {getStatusIcon(doc.status)} {doc.status}
                        </p>
                      </StatusCell>
                    </TableCell>

                    {/* Actions */}
                    <TableCell sx={{ width: '20px', cursor: "pointer" }}>
                      {renderActionCell(doc.status, doc.name)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>

      <Box className={styles.phoneContainer}>

        <DocTablePhoneScreen isError={isError} statusList={statusList} />

      </Box>
      <PhoneMenu />
    </>
  );
};

export default Doctable4;