import React, { useState, useEffect } from 'react';
import styles from './SavedProfiles.module.css';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'project-name/Redux/Store';
import OnboardingModal from './OnboardingModal';
import { fetchExportData } from 'project-name/Redux/features/FetchExportData';
import Pagination from '../RecruiterSection/Pagination';
import { updateCandidateBgvStatus } from 'project-name/Redux/features/updatebgvStatusSlice';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
} from '@mui/material';
import * as XLSX from 'xlsx';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface SavedProfile {
    full_name: string;
    tower: string;
    function: string;
    d_o_j: string;
    user_id: number;
    bgv_status: string;
    bgv_initiated: string;
}

const SavedProfilesSection: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();

    // ✅ Fetching filtered profiles from Redux
    const filteredProfiles = useSelector((state: RootState) => state.adminProfile.profiles);
    const status = useSelector((state: RootState) => state.user.status);
    const exportData = useSelector((state: RootState) => state.export.data);
    const exportError = useSelector((state: RootState) => state.export.error);
    
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [userId, setUserId] = useState<number | null>(null);
    const [open, setOpen] = useState(false);
    const itemsPerPage: number = 5;

    // ✅ Calculate total number of pages based on `filteredProfiles`
    const totalPages: number = Math.ceil(filteredProfiles.length / itemsPerPage);

    // ✅ Get paginated profiles for the current page
    const getCurrentPageProfiles = (): SavedProfile[] => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredProfiles.slice(startIndex, startIndex + itemsPerPage);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentPage]);

    const handlePageChange = (page: number): void => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleOpenConfirmation = (user_id: number) => {
        setConfirmOpen(true);
        setUserId(user_id);
    };

    const handleConfirmYes = () => {
        setConfirmOpen(false);
        handleOnboarding();
    };

    const handleOnboarding = () => {
        if (userId) {
            dispatch(updateCandidateBgvStatus({ user_id: userId }))
                .then(() => {
                    toast.success('Onboarding started successfully');
                })
                .catch(() => {
                    toast.error('Failed to start onboarding. Please try again.');
                })
                .finally(() => {
                    setOpen(true);
                });
        }
    };

    const handleClose = () => setOpen(false);

    const handleExportToExcel = async () => {
        await dispatch(fetchExportData());
        if (exportData.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(exportData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Users Data");
            XLSX.writeFile(workbook, "Users.xlsx");
        } else if (exportError) {
            toast.error('Failed to fetch export data. Please try again.');
        }
    };

    return (
        <section data-testid="profile-name">
            <div className={styles.savedProfilesContainer} style={{ padding: '0px 40px 20px 40px' }} data-testid="pagination-component">
                <div className={styles.savedProfilesHead}>
                    <h4>Saved profiles</h4>
                    <div className={styles.exportButton}>
                        <Button variant="contained" color="primary" onClick={handleExportToExcel} sx={{ textTransform: "capitalize" }}>
                            <ArrowCircleDownIcon sx={{ marginRight: 1, alignItems: "center", display: "flex" }} />
                            Export Users
                        </Button>
                    </div>
                </div>
                <TableContainer component={Paper} sx={{ boxShadow: 3, marginTop: "10px" }}>
                    <Table>
                        {/* Table Header */}
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#000', color: '#fff' }}>
                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Tower</TableCell>
                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Function</TableCell>
                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Joining Date</TableCell>
                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Bgv-Status</TableCell>
                            </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        <TableBody>
                            {getCurrentPageProfiles().map((profile, index) => (
                                <TableRow key={index}>
                                    <TableCell>{profile.full_name}</TableCell>
                                    <TableCell>{profile.tower}</TableCell>
                                    <TableCell>{profile.function}</TableCell>
                                    <TableCell>{new Date(profile.d_o_j).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        <span
                                            style={{
                                                color: profile.bgv_status === 'Pending' ? 'black' : 'white',
                                                backgroundColor:
                                                    profile.bgv_status === 'Rejected' ? 'red' :
                                                    profile.bgv_status === 'InProgress' ? 'green' :
                                                    profile.bgv_status === 'Initiated' ? 'black' : 'gray',
                                                fontWeight: 'bold',
                                                padding: '5px 10px',
                                                width: "100px",
                                                borderRadius: '5px',
                                                display: 'inline-block',
                                                textAlign: "center"
                                            }}
                                        >
                                            {profile.bgv_status === 'No' ? 'Not Initiated' : profile.bgv_status}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className={styles.ListPaginationBox}>
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                </div>
            </div>

            <OnboardingModal open={open} status={status} onClose={handleClose} />
        </section>
    );
};

export default SavedProfilesSection;
