import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import apiClient from "project-name/api/apiClient";
import axios from "axios";

interface SavedProfile {
  full_name: string;
  tower: string;
  function: string;
  employee_band: string;
  office_location: string;
  bgv_status: string;
  d_o_j: string;
  is_documents_moved: string;
  status_name: string;
  user_id: number;
}

interface FilterState {
  towers: string[];
  functions: string[];
  bands: string[];
}

interface AdminProfileState {
  profiles: SavedProfile[];
  filteredProfiles: SavedProfile[];
  filters: FilterState;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: AdminProfileState = {
  profiles: [],
  filteredProfiles: [],
  filters: {
    towers: [],
    functions: [],
    bands: [],
  },
  status: "idle",
  error: null,
};

// **Fetch Admin Profiles (Async Thunk)**
export const fetchAdminProfile = createAsyncThunk(
  "adminProfile/fetchAdminProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get("/api/admin/candidate_profiles", {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || "Failed to fetch admin profile"
      );
    }
  }
);

const adminProfileSlice = createSlice({
  name: "adminProfile",
  initialState,
  reducers: {
    // **Set Filters & Update Filtered Profiles**
    setFilters(state, action: PayloadAction<FilterState>) {
      state.filters = action.payload;
      state.filteredProfiles = state.profiles.filter((profile) => {
        return (
          (state.filters.towers.length === 0 ||
            state.filters.towers.includes(profile.tower)) &&
          (state.filters.functions.length === 0 ||
            state.filters.functions.includes(profile.function)) &&
          (state.filters.bands.length === 0 ||
            state.filters.bands.includes(profile.employee_band))
        );
      });
    },

    // **Clear Filters & Reset Profiles**
    clearFilters(state) {
      state.filters = { towers: [], functions: [], bands: [] };
      state.filteredProfiles = state.profiles;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminProfile.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAdminProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.profiles = action.payload;
        state.filteredProfiles = action.payload; // Initially, filteredProfiles is the same as profiles
      })
      .addCase(fetchAdminProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { setFilters, clearFilters } = adminProfileSlice.actions;
export default adminProfileSlice.reducer;
